.pageHeader {
  width: 100%;
  text-align: center;
  font-size: 32px;
  padding: 40px 30px 80px 30px;
  color: #ffffff;
  background-color: #0000004f;
  background-size: cover;
  background-position: bottom;
}

.pageContent {
  position: relative;
  /* top: -36px; */
  margin: 0 10px;
  background-color: #ffffff;
  border-radius: 10px;
  /* padding: 20px; */
}

.tableContainer {
  padding: 20px;
}

.tableWrapper {
  /* overflow-x: scroll; */
}

.tableContainer table {
  width: 100%;
  border-spacing: 0;
}

.tableContainer table thead th {
  padding: 5px 10px;
  background-color: #92004D;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 10px;
}

.tableContainer table.subTable thead th {
  padding: 5px 10px;
  background-color: #e4e5e8;
  text-align: left;
  color: #b5b4b4;
  text-transform: uppercase;
  font-size: 14px;
}

.tableContainer table tbody td {
  padding: 15px;
  color: #303248;
  font-size: 11px;
}

.tableContainer table tbody tr:nth-child(odd) {
  background-color: #f8f8f8;
}

.tableContainer table tbody tr:nth-child(even) {
  background-color: #ffffff;
}

.tableContainer table.subTable tbody tr:nth-child(odd),
.tableContainer table tbody tr.subtable {
  background-color: #fff9ea;
}

.tableContainer table.subTable tbody tr:nth-child(even),
.tableContainer table tbody tr.subtable {
  background-color: #fffbf4;
}

.inactivo {
  background: rgb(255, 154, 154);
  background: linear-gradient(180deg, rgb(255, 154, 154, 1) 0%, rgb(248, 248, 248, 0) 45%);
}
.caducada{
  background-color: #ffe7e7 !important;
}
.pageGrid {
  position: relative;
  top: 00px;
  margin: 0 10px;
  display: grid;
  grid-template-columns: 5fr 3fr;
  grid-gap: 20px;
}

.gridCard {
  background-color: #ffffff;
  border-radius: 10px;
}

.gridHead {
  border-radius: 10px 10px 0 0;
  background-color: #92004D;
  color: #ffffff;
  padding: 10px 15px;
  font-weight: bold;
  font-size: 14px;
  height: 40px;
  display: flex;
  justify-content: space-between;
}

/* .gridHeadFiltros {
  border-radius: 10px 10px 0 0;
  background-color: #92004D;
  color: #ffffff;
  padding: 10px 15px;
  font-weight: bold;
  font-size: 14px;
  height: 40px;
  display: flex;
  align-items: center; 
  justify-content: space-between;
  margin-bottom: 10px; 
}




.gridCardFiltros {
  background-color: #ffffff;
  border-radius: 10px;
  align-items: center; 
  justify-content: space-between; 
  margin-bottom: 10px; 
} */


.filtros-container {
  display: flex;
  flex-wrap: wrap; 
  gap: 5px; 
  /* justify-content: space-around; */
  
}

.filtrosContent{
  position: relative;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 8px;
  margin-bottom: 20px;
}

.filtrosContent .titleLabel{
  position: absolute;
  top: -15px;
  left: 15px;
  background: #fff;
  padding: 0 10px;
  color:#303248;
}

.gridHeadRight {
  color: #ffffff;
  text-align: right;
  font-weight: normal;
}

.gridHeadRight:hover {
  text-decoration: underline;
}

.gridSubhead {
  background-color: #fafafa;
  color: #303248;
  padding: 10px 15px;
  font-size: 12px;
  font-weight: bold;
  border-left: #303248 4px solid;
}

.btnsSubhead {
  display: flex;
}

.editarSubhead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editarSubhead .editarBtn,
.editarSubhead .subheadBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0 5px;
}

.editarSubhead img {
  width: 15px;
}

.listPage {
  position: relative;
  /* top: -40px; */
  margin: 0 10px;
}

.datosGrid {
  position: relative;
  margin: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  font-size: 12px;
}

.datosGridComentarios {
  position: relative;
  margin: 20px;
  font-size: 12px;
}

.tableIcon {
  width: 20px;
  margin: 7px;
  cursor: pointer;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.contentBtns {
  display: flex;
  align-items: center;
}

.contentButton {
  background-color: #99e666;
  color: #ffffff;
  padding: 5px 13px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
  font-size: 15px;
  height: 35px;
  /* width: 35px; */
}

.contentButton img {
  height: 10px;
  /* margin-right: 10px; */
}

.contentButton span {
  height: 10px;
  margin-left: 10px;
}

.asignarButton {
  background-color: #92004D;
  color: #ffffff;
  padding: 8px 15px;
  width: fit-content;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.asignarButton img {
  height: 10px;
  margin-right: 10px;
}

.titleContent {
  color: #303248;
  font-size: 24px;
  font-weight: bold;
  border-left: #92004D 5px solid;
  padding-left: 10px;
  margin: 10px 0;
}

.nuevoAAFFForm {
  display: flex;
  flex-direction: column;
  margin: 25px;
  padding-bottom: 25px;
}

.nuevoAAFFForm select {
  width: 100%;
  padding: 8px 15px;
  border-bottom: #303248 1px solid;
}

.nuevoAAFFForm input {
  width: 100%;
  padding: 10px 15px;
  border-bottom: #303248 1px solid;
}

.nuevoAAFFForm input[type="submit"],
.nuevoAAFFForm input[type="button"] {
  padding: 10px 15px;
  color: #ffffff;
  background-color: #92004D;
  border-radius: 5px;
  font-weight: bold;
  width: fit-content;
  align-self: flex-end;
  border: none;
}

.listadoBtn {
  padding: 5px 12px;
  color: #92004D;
  border-radius: 5px;
  width: fit-content;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.descargarBtn {
  padding: 5px 12px;
  display: flex;
  align-content: center;
  color: #92004D;
  width: fit-content;
  cursor: pointer;
}

.descargarBtn img {
  height: 15px;
  margin-right: 10px;
}

.bigBtn {
  padding: 10px 10px;
  display: flex;
  align-items: center;
  background-color: #92004D;
  width: fit-content;
  cursor: pointer;
  font-weight: bold;
  border-radius: 5px;
  color: #ffffff;
  margin: 0 10px;
  font-size: 12px;
}
.bigBtn.disabled {
  background-color: #ccc; /* Color gris para botón deshabilitado */
  border: solid #92004D 1px !important;
  color: #92004D; /* Color de texto más oscuro */
}
.bigBtn a{
  flex-wrap: nowrap;
  align-items: center;
  display: flex;
}

.bigBtn.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.bigBtn img {
  height: 20px;
  margin-right: 15px;
  filter: brightness(0) invert(1);
}

.bigBtn svg {
  font-size: 20px;
  filter: brightness(0) invert(1);
}

.buscador,
.buscadorFiltros {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

.buscador button {
  padding: 3px 10px 7px;
  color: #ffffff;
  background-color: #92004D;
  border-radius: 5px;
  font-weight: bold;
  width: fit-content;
  align-self: flex-end;
  border: none;
}

.buscador button:hover {
  background-color: #92004D;
}

.buscador input {
  padding: 4px 10px;
  border-radius: 5px;
  border: #7e86a3 1px solid;
  margin-right: 10px;
  margin-bottom: 2px;
  min-width: 210px;
}

.buscador input:focus-visible {
  border: none;
}

.buscador p {
  margin-left: 20px;
  padding: 5px 15px 0px;
  font-size: 14px;
}

.buscador select {
  padding: 5px 10px;
  border-radius: 5px;
  border: #7e86a3 1px solid;
  margin-right: 10px;
  margin-bottom: 2px;
  min-width: 210px;
}

.exportar {
  display: flex;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  flex-direction: row-reverse;
}

.hojaVisitaContainer {
  padding: 30px 50px;
}

.hojaVisitaContainer label {
  display: block;
  padding-bottom: 10px;
}

.hojaVisitaContainer input[type="text"],
.hojaVisitaContainer input[type="number"],
.hojaVisitaContainer input[type="date"],
.hojaVisitaContainer select {
  background-color: #ffffff;
  width: 100%;
  padding: 10px 15px;
}

.hojaVisitaContainer input:disabled {
  background-color: #ffffff;
  color: #303248;
}

.hojaVisitaContainer img {
  width: 100%;
}

.hojaVisitaContainer h2 {
  margin-top: 40px;
  margin-bottom: 20px;
}

.hojaVisitaContainer input[type="radio"] {
  margin-right: 10px;
}

.hojaVisitaContainer input[type="submit"] {
  padding: 10px 15px;
  color: #ffffff;
  background-color: #92004D;
  border-radius: 5px;
  font-weight: bold;
  width: fit-content;
  align-self: flex-end;
  border: none;
  margin: 20px 0 20px auto;
}

.radioGroup {
  margin-bottom: 30px;
}

.contentBar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

@media print {
  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group;
  }

  .pagebreak {
    clear: both;
    page-break-after: always;
  }
}

@media (max-width: 768px) {
  .buscador {
    flex-direction: column;
  }

  .buscador input,
  .buscador select {
    margin-bottom: 10px;
  }

  .buscador button {
    margin-right: 10px;
  }
}

@media (max-width: 1100px) {
  .lae-flex-row {
    flex-direction: column;
  }
}

.css-196vw67-MuiButtonBase-root-MuiTableSortLabel-root,
.css-zcbmsk-MuiButtonBase-root-MuiButton-root {
  font-size: 10px !important;
}

.css-1ex1afd-MuiTableCell-root {
  font-size: 11px !important;
  text-align: center;
}

/* .css-ahj2mt-MuiTypography-root{
  font-size: 11px !important;
} */
.MuiBox-root div p {
  font-size: 11px !important;
}

/* .tableContainer table tbody td .css-ahj2mt-MuiTypography-root{
  text-align: center;
} */
.tableContainer table tbody td .MuiTypography-root {
  text-align: center;
}

/* .css-jd1zyo-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select {
  font-size: 12px;
} */
.MuiSelect-select.MuiSelect-select {
  font-size: 12px;
}

.css-rorn0c-MuiTableContainer-root {
  border-radius: 5px;
}

.MuiAccordionSummary-contentGutters p {
  font-size: 0.7rem;
}

.css-1thazht {
  min-height: auto !important;
}

.checkBoxCustom,
.checkBoxCustomTop {
  display: none;
  /*solo se esconde para tener un campo personalisado*/
}

.checkBoxCustom+.labelCustom,
.checkBoxCustomTop+.labelCustom {
  cursor: pointer;
}

/*esta linea significa esto + el siguiente elemento*/

.labelCustom:before {
  content: '';
  background: transparent;
  border: 3px solid #92004D;
  border-radius: 5px;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-right: 20px;
  text-align: center;
  vertical-align: middle;
}

.checkBoxCustom:checked+.labelCustom:before {
  content: '✔';
  font-size: 11px;
  font-family: 'Times New Roman';
  color: #92004D;
}

.checkBoxCustomTop:checked+.labelCustom:before {
  content: '✔';
  font-size: 11px;
  font-family: 'Times New Roman';
  color: #ffffff;
}

.filtros .MuiInputBase-formControl {
  width: 210px;
}

.filtros,
.filtros .MuiInputBase-formControl {
  height: 2.5em;
  margin: 5px 5px;
}

.altura {
  height: 50px;
}

.filtros-select {
  width: 220px;
  /* margin: 10px 0 !important; */
}

.css-1r8wrcl-MuiLinearProgress-bar2 .css-5ir5xx-MuiLinearProgress-bar1{
  background-color: #92004D !important;
}
.css-1ooucff-MuiLinearProgress-root{
  background-color: #ef95c5 !important;
}